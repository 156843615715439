define("ember-welcome-page/components/welcome-page", ["exports", "ember-welcome-page/templates/components/welcome-page"], function (_exports, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    layout: _welcomePage.default,
    emberVersion: Ember.computed(function () {
      var _Ember$VERSION$split = Ember.VERSION.split("."),
          _Ember$VERSION$split2 = _slicedToArray(_Ember$VERSION$split, 2),
          major = _Ember$VERSION$split2[0],
          minor = _Ember$VERSION$split2[1];

      return "".concat(major, ".").concat(minor, ".0");
    }),
    canAngleBracket: Ember.computed(function () {
      return true;
    }),
    isModuleUnification: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config && config.isModuleUnification;
    })
  });

  _exports.default = _default;
});